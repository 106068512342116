<template>
    <seccion-datos :titulo="estadopendientedatos" @cambios="guardar()">

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                    <input type="text" class="form-control nombre_completo dato" id="titulo_texto" placeholder="" v-model="titulo_texto">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="nombre_completo">Tipo</label>
                    <select class="form-control" v-model="tipo_mensaje" name="tipo_mensaje">
                        <option v-for="tipo in tipos" :value="tipo" :key="tipo">{{ tipo }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="nombre_completo">Texto</label>
                    <textarea class="form-control dato" name="texto" placeholder="" rows="15" v-model="texto_mensaje"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="mostrarPalabras()">Palabras clave</button>
            </div>
        </div>  
        <Dialog :style="{ width: '50rem' }" header="Palabras clave" modal v-model:visible="visiblePalabras">
            <div style="overflow: auto;">
                <p v-for="palabra in palabrasClave" :key="palabra" style="margin: 0;">{{ palabra }}</p>
            </div>
        </Dialog>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: {
        id: Number,        
    },
    data() {
        return {
            estadopendientedatos: "Datos", 
            mensaje_predefinido: Object,
            titulo_texto: '',
            tipo_mensaje: '',
            texto_mensaje: '',
            tipos:[],
            visiblePalabras:false,
            palabrasClave: [],
        }
    },
    methods: {
        async mostrarPalabras() {
            const api = new PwgsApi();
            this.palabrasClave = await api.get('mensajes-predefinidos/palabras-clave');
            this.visiblePalabras = true;
        },
        async cargardatos() {
           
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const datos = await api.get('mensajes-predefinidos/' + this.id);
            this.mensaje_predefinido = datos.datos;
            console.log('this.mensaje_predefinido', this.mensaje_predefinido);
            this.$store.dispatch('cambiarCargando', false);

            this.titulo_texto = this.mensaje_predefinido.titulo_texto;
            this.tipo_mensaje = this.mensaje_predefinido.tipo_mensaje;
            this.texto_mensaje = this.mensaje_predefinido.texto;
            
            this.tipos=["sms", 'email'];

            var wa = await api.get('parametros-pwgs/whatsapp');
            if(wa.whatsapp=='1'){
                this.tipos.push('whatsapp');
            }
          
        },
        async guardar() {
            const api = new PwgsApi;
            const datos = { titulo_texto : this.titulo_texto, tipo_mensaje: this.tipo_mensaje, texto: this.texto_mensaje };            
            try {
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('mensajes-predefinidos/' + this.id, datos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$parent.obtenerMensajePredefinido(this.id);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
                catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            
        },
        comprobar() { }
    },
    mounted() {
        this.cargardatos();
    }
}
</script>